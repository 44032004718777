import { useEffect, useState } from 'react';
import '../styles/Home.css';
import MyNav from './Navbar';
import 'react-bootstrap'
import ContactForm from './ContactForm';
import PressQuotes from './PressQuotes';
import backgroundVid from '../videos/background-vid.mp4';
import backgroundVidShort from '../videos/background-vid-short.mp4';

const homeTitleDivStyle = {
  // fontSize: '60px',
  // fontWeight: 'bold',
  // textAlign: 'center',
  // textDecoration: 'none',
  // mixBlendMode: 'difference',
  // color: 'white',
  // zIndex: 1,
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
}

const homeTitleH1Style = {
  // fontFamily: 'Assistant, sans-serif',
  // fontSize: '98px',
  // fontWeight: '800',
  // fontStyle: 'normal',
  // textTransform: 'uppercase',
  // letterSpacing: '4.9px',
  // lineHeight: '107.8px',
  // WebkitFontSmoothing: 'antialiased',
}


function Home({ isHomePage, isAboutPage, isContactPage, isPhotosPage, isPressPage }) {
  const [showBio, setShowBio] = useState(false);
  const [currentPage, setCurrentPage] = useState({
    isHomePage, isAboutPage, isContactPage, isPhotosPage, isPressPage
  })

  useEffect(() => {
    if (isAboutPage) {
      setShowBio(true);
    }
  }, [])

  return (
    <div className="home">

      <MyNav currentPage={currentPage} />


      {/* BACKGROUND VIDEO (long) */}
      {isHomePage &&
        <video playsInline autoPlay muted loop
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        >
          <source src={backgroundVid} type="video/mp4" />
        </video>
      }

      {/* BACKGROUND VIDEO (short) */}
      {!isHomePage &&
        <video playsInline autoPlay muted loop
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        >
          <source src={backgroundVidShort} type="video/mp4" />
        </video>
      }

      {isHomePage &&
        <div>
          <div id='bellbird-title' style={homeTitleDivStyle}>
            <h1 style={homeTitleH1Style}>
              Bellbird
            </h1>
          </div>

          {/* album announcement */}
          {/* <div className='section1 semi-transparent-background'>
            <h1 style={{ fontSize: '60px' }}><a href='https://bellbirdband.bandcamp.com/album/root-in-tandem' target='_blank' style={{ color: 'inherit', textDecoration: 'inherit' }}><span style={{ fontWeight: 'bold' }}>Root in Tandem</span></a><br></br> out now on all platforms</h1>
          </div> */}

          {/* <img
            // style make image smalller
            style={{ width: '30%', height: 'auto' }}
            src={process.env.PUBLIC_URL + '/images/Cover-RootInTandem.jpg'}
            alt="bellbird"
          /> */}

          {/* ****************** */}
          {/* ****************** */}
          {/* ****************** */}
          {/*   TODO TODO TODO   */}
          {/*     REACTIVATE     */}
          {/* ****************** */}
          {/* ****************** */}
          {/* ****************** */}
          {/* <div className='section1 semi-transparent-background'>
            <h3>
              <a href="/email-signup">Sign up</a> for our email list to stay updated on Bellbird news!
            </h3>
          </div> */}
        </div>
      }

      {
        showBio &&
        <div>
          <h1 className='page-title'>About</h1>
          <div className='section1'>
            <div className='bio semi-transparent-background-dark'>
              Bellbird is collective of Montreal-based musicians. Their unique instrumentation of two woodwinds, bass, and drums, allows them to explore sonic landscapes that allude to harmony without being restricted by it. Original compositions by each band member showcase their diverse sets of influences, including jazz, avant-garde, and folk music.
              <br />
              <br />
              <br />
              <p> Personnel:</p>
              <p>
                <a className='name-link' href='http://www.allisonburik.com/' target='_blank'>Allison Burik</a> - alto saxophone, bass clarinet <br></br>
                <a className='name-link' href='https://clairedevlin.com/' target='_blank'>Claire Devlin</a> - tenor saxophone <br></br>
                <a className='name-link' href='https://www.elidavidovici.com/' target='_blank'>Eli Davidovici</a> - bass <br></br>
                <a className='name-link' href='http://www.milihong.com/' target='_blank'>Mili Hong</a> - drums
              </p>
            </div>
          </div>
          
        </div>
      }
      {
        isPhotosPage &&
        <div className='section1'>
          <div className='semi-transparent-background'>
            Photos
          </div>
        </div>

      }
      {
        isPressPage &&
        <div>
          <h1 className='page-title'>Press</h1>
          <div className='section1'>
            <div className='semi-transparent-background-light' style={{ padding: '20px' }}>
              <PressQuotes />
            </div>
          </div>
        </div>
      }
      {
        isContactPage &&
        <div className='section1'>
          <h1 className='page-title'>Contact</h1>
          <h3 className=''>
            Contact us
          </h3>
          <ContactForm />
        </div>
      }
    </div >
  );
}

export default Home;
