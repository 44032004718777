import React from 'react';

function PressQuotes() {

    return (
        <div className='press-quotes'>
            {/* <h2>Press Quotes</h2> */}
            {/* <br /> */}
            <div className='press-quote-individual'>
                <p>A cohesive mood is maintained from beginning to end. If it can be tagged free jazz, it is the most approachable and melodious kind. The vibe keeps a hold on serenity even when the leash is loosened, suggesting a certain level of organic harmony was maintained in a small cabin in isolation during the process of creation. It proved to be a good way to make music. ★★★1/2</p>
                <p><a href="https://www.allaboutjazz.com/root-in-tandem-bellbird-self-produced" target='_blank'>Dan McClenaghan – All About Jazz</a></p>
            </div>
            <hr className='quote-separator'></hr>
            <div className='press-quote-individual'>
                <p>Fascinating four-piece. ★ ★ ★ ★ ★ ☆</p>
                <p><a href="https://klassekampen.no/artikkel/2023-08-18/fascinerende-firklover" target='_blank'>Chris Monsen – Klassekampen (Norway)</a></p>
            </div>
            <hr className='quote-separator'></hr>
            <div className='press-quote-individual'>
                <p>Often a band touts itself as a democratic collective but with Bellbird, it truly sounds that way. The distinguishing voice is not one or two players but the entire band, with Devlin and Burik fitting together nicely to make up a rich, lithe harmonious unit. Meanwhile the rhythm section of Davidovici and Hong complement them by setting the right tempo and cadence. Everyone’s so devoted to harmony that the lack of a guitar of piano isn’t noticed at all.</p>
                <p><a href="https://somethingelsereviews.com/2023/09/01/bellbird-pigeons-disco-from-root-in-tandem-2023-something-else-video-premiere/">Something Else!</a></p>
            </div>
            <hr className='quote-separator'></hr>
            <div className='press-quote-individual'>
                <p>A relaxed, explorative feel informs the performances, an effect no doubt attributable in part to the wintry location of the recording studio. That generally works in the album's favour, encouraging as it does an explorative receptivity to let the music take the four where it will. There are moments where the playing suggests some modern-day mashup of Tim Berne, Charlie Haden, and The Lounge Lizards, and the to-and-fro between Devlin and Burik and the locked-in rumble of Davidovici and Hong are compelling… featuring thoughtfully structured compositions and empathetic group interplay, Root in Tandem qualifies as a solid debut statement.</p>
                <p><a href="https://www.textura.org/archives/b/bellbird_rootintandem.htm">Ron Schepper - Textura</a></p>
            </div>
            <hr className='quote-separator'></hr>
            <div className='press-quote-individual'>
                <p>Eli's second tune “Ten Dog” is one of my favourites. Featuring drums and then alto at the start, it takes shape into the most swinging — in an Afro-Cuban style groove — time on the record. It then takes on surprisingly different shapes for the other soloists… It's joyous and possibly even therapeutic to hear these four each contribute something from their ongoing creative practices to a well-balanced new group.</p>
                <p><a href="https://www.rhythmchanges.ca/bellbird-root-in-tandem/">Will Chernoff – Rhythm Changes</a></p>
            </div>
        </div>
    );

}

export default PressQuotes;