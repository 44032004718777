import React from "react";


function PageNotFound() {

    return (
        <div className="home">
            <div className='background-image'></div>
            <div className="section1">
                <h3 className='semi-transparent-background'>
                    Page not found
                </h3>
            </div>
        </div>
    )
}

export default PageNotFound;