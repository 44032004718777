import MyNav from './Navbar';
import backgroundVidShort from '../videos/background-vid-short.mp4';
import TourDateList from './bandsintownwidget/tour-date-list';
import { useEffect, useState } from 'react';
import '../styles/Bandsintown.css';


function Events({ }) {
    const [state, setState] = useState({
        tourDates: []
    });

    useEffect(() => {
        fetch('https://rest.bandsintown.com/artists/Bellbird/events?app_id=Test')
            .then(response => response.json())
            .then(data => {
                setState({
                    tourDates: data
                })
            })
    }, [])

    return (
        <div className="events">
            {/* <div className='background-image'></div> */}
            <video playsInline autoPlay muted loop
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                }}
            >
                <source src={backgroundVidShort} type="video/mp4" />
            </video>
            <MyNav isTourPage />
            <h1 className='page-title'>Tour</h1>

            <div className='section1'>
                <TourDateList tourDates={state.tourDates} />
            </div>
        </div>
    )
}

export default Events;