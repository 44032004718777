import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const buttonSelectedStyle = {
    borderBottom: 'solid 1px black' // TODO BRING THIS BACK ONCE NAVBAR IS ABSOLUTE NOT FIXED
}

function MyNav({ currentPage, isTourPage }) {

    return (
        <Navbar
            sticky='top'
            expand="lg"
            className='navbar-claire'
        >
            <Container className='container-claire' >
                {/* <Navbar.Brand href="/">Bellbird</Navbar.Brand> */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="navbar-collapse-id" style={{
                    // display: 'flex',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                }}>
                    <Nav className="">
                        <a className='nav-link-claire' href="/">Home</a>
                        <a className='nav-link-claire' style={currentPage?.isAboutPage && buttonSelectedStyle} href="/about">About</a>
                        <a className='nav-link-claire' style={isTourPage && buttonSelectedStyle} href="/tour">Tour</a>
                        {/* <a href="/photos">Photos</a> */}
                        <a className='nav-link-claire' style={currentPage?.isPressPage && buttonSelectedStyle} href="/press">Press</a>
                        <a className='nav-link-claire' href="https://bellbirdband.bandcamp.com/" target='_blank' >
                            Music <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ fontSize: '12px' }} />
                        </a>
                        <a className='nav-link-claire' href="https://bellbirdband.bandcamp.com/merch" target='_blank' >
                            Merch <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ fontSize: '12px' }} />
                        </a>
                        <a className='nav-link-claire' style={currentPage?.isContactPage && buttonSelectedStyle} href="/contact">Contact</a>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MyNav;

// https://docs.google.com/spreadsheets/d/e/2PACX-1vQ3aoX0rj0Wmk_j4_4ALehbu5r9FODW5oLH3G3Tugbqsr7M6OpzvXzXPSUD98IohlpGQVG2kLT4e1Nn/pubhtml