import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './styles/index.css';
import Home from './components/Home';
import reportWebVitals from './reportWebVitals';
import Events from './components/Events';
import EmailSignUp from './components/EmailSignUp';
import PageNotFound from './components/PageNotFound';

import Epk from './components/Epk';


// TODO: Add a 404 page
// 💿 Hey developer 👋 
// You can provide a way better UX than this when your app throws errors 
// by providing your own ErrorBoundary or errorElement prop on your route.


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home isHomePage />,
  },
  {
    path: "/email-signup",
    element: <EmailSignUp />,
  },
  {
    path: "/about",
    element: <Home isAboutPage />,
  },
  {
    path: "/tour",
    element: <Events />,
  },
  {
    path: "/photos",
    element: <Home isPhotosPage />,
  },
  {
    path: "/contact",
    // element: <Home isContactPage />,
    element: <EmailSignUp />,

  },
  {
    path: "/epk",
    element: <Epk />,
  },
  {
    path: "/press",
    element: <Home isPressPage />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  }
])


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <div className='global'>
      <RouterProvider router={router} />
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
