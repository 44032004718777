import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const formInputStyle = {
    borderRadius: '0'
}

const contactButtonStyle = {
    color: 'white',
    textDecoration: 'none',
    padding: '1rem 1.825rem',
    fontFamily: "'Assistant', sans-serif",
    fontWeight: '700',
    fontSize: '19px',
    borderRadius: '0',
    // backgroundColor: '#d2aa65', TODO: make submit button be inverse of background color using mix-blend-mode
}


const FORM_ENDPOINT = ""; // TODO - fill on the later step

const ContactForm = () => {
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = () => {
        setTimeout(() => {
            setSubmitted(true);
        }, 100);
    };

    if (submitted) {
        return (
            <>
                <h2>Thank you!</h2>
                <div>We'll be in touch soon.</div>
            </>
        );
    }

    return (
        <Form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            target="_blank"
        >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control style={formInputStyle} type="text" placeholder="*Your name" name="name" required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Form.Control style={formInputStyle} type="email" placeholder="*Email" name="email" required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                <Form.Control style={formInputStyle} as="textarea" placeholder="*Your message" name="message" required />
            </Form.Group>
            <Form.Group>
                <Button style={contactButtonStyle} type="submit"> Submit </Button>
            </Form.Group>
        </Form>
    );
};

export default ContactForm;