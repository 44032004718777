import React, { useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../styles/Home.css';
import emailjs from '@emailjs/browser';
import MyNav from './Navbar';
import backgroundVidShort from '../videos/background-vid-short.mp4';


const formInputStyle = {
    borderRadius: '0'
}

const contactButtonStyle = {
    color: 'white',
    textDecoration: 'none',
    padding: '1rem 1.825rem',
    fontFamily: "'Assistant', sans-serif",
    fontWeight: '700',
    fontSize: '19px',
    borderRadius: '0',
    // backgroundColor: '#d2aa65', TODO: make submit button be inverse of background color using mix-blend-mode
}


function EmailSignUp() {
    const [submitSuccess, setSubmitSuccess] = useState(false)

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_7e62owq', 'template_qg3vdy9', form.current, 'xsJKQJomrOU6aG6FS')
            .then((result) => {
                console.log(result.text);
                form.current.reset();
                setSubmitSuccess(true)
            }, (error) => {
                console.log(error.text);
            });
    };


    return (
        <div className="home">
            {/* <div className='background-image'></div> */}
            <MyNav />
            <video playsInline autoPlay muted loop
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                }}
            >
                <source src={backgroundVidShort} type="video/mp4" />
            </video>
            <h1 className='page-title'>Contact</h1>
            <br />    

            {submitSuccess &&
                <div className="section1" style={{ display: 'flex', justifyContent: 'center' }}>
                    <h4 className='semi-transparent-background' style={{
                        padding: '5px 15px',
                        borderRadius: '0.375rem',
                        boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)'
                    }}>Success!</h4>

                </div>
            }
            {!submitSuccess &&
                <div className="section1 ">
                    {/* <h3 className='semi-transparent-background'
                        style={{
                            display: 'inline-block',
                            marginBottom: '1em',
                            padding: '5px 15px',
                            borderRadius: '0.375rem',
                            boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)'
                        }}>
                        Sign up for Bellbird's email list!
                    </h3> */}
                    <Form
                        className="email-form"
                        ref={form}
                        onSubmit={sendEmail}
                        method="POST"
                        target="_blank"
                        style={{ maxWidth: '500px', margin: 'auto' }}
                    >
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" placeholder="*Your name" name="name" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Control type="email" placeholder="*Email" name="email" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Control type="text" placeholder="*City" name="city" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                            <Form.Control type="text" placeholder="*Country" name="country" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                            <Form.Control type="text" placeholder="Message" name="message" />
                        </Form.Group>
                        {/* TODO: add checkbox for email signup */}
                        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                            <Form.Check type="checkbox" name="sign-up" label='Sign up for email list' id="sign-up-checkbox" />
                        </Form.Group> */}
                        <Form.Group>
                            <Button
                                type="submit"
                                style={contactButtonStyle}
                            >
                                Contact
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            }
        </div>
    )
}

export default EmailSignUp;