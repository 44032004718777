import React from 'react';
import MyNav from './Navbar';
import PressQuotes from './PressQuotes';


function Epk() {

    // TODO: add Bellbird logo/link to home page in top left corner
    // TODO: add photo download link
    // TODO: add other links to google drive?
    // TODO: add video

    return (
        <div className='epk'>
            {/* <div className='background-image'></div> */}

            <MyNav />

            <div className='section1 semi-transparent-background-light'>
                <h1 style={{ fontSize: '60px' }}>Electronic Press Kit</h1>

                {/* Music samples */}
                <div className='epk-section'>

                    <h3>Music</h3>
                    {/* <iframe
                    style={{ border: 0, width: '100%', height: '120px' }}
                    src="https://bandcamp.com/EmbeddedPlayer/album=427035035/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
                    seamless
                > */}
                    <iframe
                        style={{ border: '0', width: '500px', height: '406px' }}
                        src="https://bandcamp.com/EmbeddedPlayer/album=2692883278/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/"
                        seamless
                    >
                        <a href="https://bellbirdband.bandcamp.com/album/root-in-tandem">
                            Root in Tandem by Bellbird
                        </a>
                    </iframe>


                    {/* <a href="https://bellbirdband.bandcamp.com/album/root-in-tandem">Root in Tandem by Bellbird</a>
            </iframe> */}
                </div>



                {/* videos */}
                <div className='epk-section'>
                    {/* <h3>Videos</h3> */}
                    {/* TODO !!!!!!!!!!!!!!!!! */}
                    {/* TODO !!!!!!!!!!!!!!!!! */}
                    {/* TODO !!!!!!!!!!!!!!!!! */}
                    {/* TODO !!!!!!!!!!!!!!!!! */}
                    {/* TODO !!!!!!!!!!!!!!!!! */}
                    {/* TODO !!!!!!!!!!!!!!!!! */}
                </div>




                {/* Press Quotes */}
                <div className='epk-section'>
                    <h3>Press Quotes</h3>
                    <PressQuotes />
                </div>




                {/* photos */}
                <div className='epk-section'>
                    {/* <h3>Photos</h3> */}

                    {/* TODO !!!!!!!!!!!!!!!!! */}
                    {/* TODO !!!!!!!!!!!!!!!!! */}
                    {/* TODO !!!!!!!!!!!!!!!!! */}
                    {/* TODO !!!!!!!!!!!!!!!!! */}
                    {/* TODO !!!!!!!!!!!!!!!!! */}
                    {/* TODO !!!!!!!!!!!!!!!!! */}
                    {/* TODO !!!!!!!!!!!!!!!!! */}
                </div>




                {/* bios */}
                <div className='epk-bios'>
                    <h3>Bios</h3>
                    <h6>83 words</h6>
                    <p>Bellbird is a collective of Montreal-based musicians. Their unique instrumentation of two woodwinds, bass, and drums allows them to explore sonic landscapes that allude to harmony without being restricted by it. The four members of Bellbird grew up in disparate situations, spanning three different countries and emerging from wildly different musical scenes. Original compositions by each band member showcase their diverse sets of influences, including jazz, avant-garde, and folk music. Bellbird’s debut album Root In Tandem was released worldwide on August 18th, 2023.</p>

                    <h6>204 words</h6>
                    <p>Bellbird’s debut album Root In Tandem was released worldwide on August 18th 2023. It is a culmination of several years of composing and music-making by tenor saxophonist Claire Devlin, alto saxophonist and bass clarinetist Allison Burik, bassist Eli Davidovici, and drummer Mili Hong. Recorded during the winter at a lakeside cottage in St Zenon, Quebec, the album represents a communion of four distinctive spirits putting all their creative weight into a shared artwork. The band is a collective, with each member contributing compositions.
                        <br />
                        <br />
                        The four members of Bellbird grew up in disparate situations, spanning three different countries and emerging from wildly different musical scenes. The quartet found each other in Montreal in 2021, and it took no time at all for them to realize that they’d found kindred musical spirits in each other.
                        <br />
                        <br />
                        While rooted in modern jazz, Bellbird’s music bears influences from myriad musical styles, from moving folk melodies, to atonal counterpoint, to rock grooves that stretch the boundaries of the jazz medium. Capturing the ethos of the band, the music on their debut album grows out of a mutual curiosity and creativity, with feelings of space and calm pierced by exhilarating solos and free improvisation, always keeping the listener on their toes.
                    </p>

                    <h6>327 words</h6>
                    <p>Formed in the summer of 2021, Bellbird’s music is rooted in modern jazz and melds influences from experimental, rock, improvised, and folk music from Canada and abroad. Each musician brings their own unique set of influences to the collective, creating a balance between noisy experimentation and more traditional compositional elements. Tenor saxophonist Claire Devlin hails from Ottawa originally, and brings her cellular composition technique to the ensemble repertoire. Her improvisational style oscillates between minimalist statements and fiery displays of melody and noise. Her playing pairs naturally with Allison Burik’s alto sax and bass clarinet playing; their own improvisational style pays homage to their Kansas City childhood with soulful melodies and bebop lines, paired with bursts of free improvisation and extended techniques. Mili Hong, emigrating to Canada from South Korea at the age of 20, and Eli Davidovici, a double-bassist hailing from Vancouver, B.C., have worked together as an improvised performance duo and rhythm section for-hire for over a decade. Mili’s explosive style of drumming is matched by her remarkable sensitivity and dynamic improvisational playing. She has built a powerful relationship and chemistry with Eli, whose warm and supportive bass sound juxtaposes with a sensibility towards angular melodies and odd rhythms. Through this process of getting to know each other, musically and personally, the members of Bellbird have delighted in creating compositions for their unique instrumentation.
                        <br />
                        <br />
                        Bellbird’s music is inspired by the sounds of nature and the shared experience between humans and nonhumans. The band name is derived from the white bellbird – an animal known for creating the loudest call recorded – uniting the themes of nature and music.  The link between sound and breath - for birds and for woodwinds - influences the melodies and rhythms of the band’s repertoire. The band is also fascinated by the variation of movement through space and time, and this is reflected in the fluidity of their time feel, which allows them to superimpose different rhythms and grooves that would otherwise seem unrelated.
                        <br />
                        <br />
                        Bellbird’s debut album Root In Tandem was released worldwide on August 18th 2023. While rooted in modern jazz, this music bears influences from myriad musical styles, from moving folk melodies, to atonal counterpoint, to rock grooves that stretch the boundaries of the jazz medium. Capturing the ethos of the band, the music on Root In Tandem grows out of a mutual curiosity and creativity, with feelings of space and calm pierced by exhilarating solos and free improvisation, always keeping the listener on their toes.
                    </p>

                </div>









            </div>



        </div >
    );

}

export default Epk;
